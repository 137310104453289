<template>
  <div id="app">
    <div class="container">
      <div class="header">
        <!-- 头部 -->
        <div class="top">
          歡迎入住暮舍&皇家巴黎賓館
          <p>Welcome to THE NIGHT & Royal Paris Guest House</p>
        </div>

        <div class="bottom">
          <div class="bottom_left" >

            
          </div>
          
          <div class="bottom_right">
            <div class="bottom_top">
              <div @click="orders">
                <div>订单号搜索 </div>
                <div>Search by Booking Number</div>
              </div>

              <div @click="names">
                <div>預訂名字搜索 </div>
                <div>Search by Booking name</div>
              </div>
            </div>

            <div class="bottom_bom" v-if="shows == 0">
              <div class="bottom_input">
                <div style="width: 60px;">訂單號</div>
                <div>Booking Number</div>
                <input v-model="orderNo" style="" class="orderHao" type="text" placeholder="请输入订单号">
              </div>
              <button class="btn" @click="getList">
                查詢Search
              </button>
            </div>

            <div class="zhanshis" v-if="shows == 0">
              
              <div class="room">
                <div>
                  房間號Room Number :
                </div>
                <div>
                  {{ this.room }}
                </div>
              </div>
              <div class="room">
                <div>
                  房門密碼Room Door Code :
                </div>
                <div>
                  {{ this.password }}
                </div>
              </div>
              <div class="room">
                <div>
                  大門密碼Front Door Code :
                </div>
                <div>
                  {{ this.doorps }}
                </div>
              </div>
              <div class="room">
                先激活鎖鍵盤，再輸入密碼，以# 或🔒結束
                <br>
                Activate the keypad, enter the door code and then press the # or 🔒to unlock
              </div>
              <div class="room">
                WiFi:The_Night &emsp;&emsp;&emsp;
                Password:93831757
              </div>
            </div>


            <div class="bottom_bom" v-if="shows == 1">

              <div class="bottom_input">
                <div>姓名</div>
                <div>Booking Name</div>
                <input v-model="mingzi" class="orderHao" type="text" placeholder="请输入名称">
              </div>

              <div class="bottom_input">

                <div>入住日期</div>
                <div>Checking in Date</div>
                <input class="orderHao" v-model="value1" type="date">
              </div>

              <button class="btn" @click="getListname">
                查詢Search
              </button>
            </div>


            <div class="zhanshi" v-if="shows == 1">
              
              <div class="room">
                <div>
                  房間號Room Number :
                </div>
                <div>
                  {{ this.room }}
                </div>
              </div>
              <div class="room">
                <div>
                  房門密碼Room Door Code :
                </div>
                <div>
                  {{ this.password }}
                </div>
              </div>
              <div class="room">
                <div>
                  大門密碼Front Door Code :
                </div>
                <div>
                  {{ this.doorps }}
                </div>
              </div>
              <div class="room">
                先激活鎖鍵盤，再輸入密碼，以# 或🔒結束
                <br>
                Activate the keypad, enter the door code and then press the # or 🔒to unlock
              </div>
              <div class="room">
                WiFi:The_Night &emsp;&emsp;&emsp;
                Password:93831757
              </div>
            </div>


          </div>
        </div>
        <div class="rooms">
                24小時客服:93831757
                <br>
                24hours CS (WeChat & WhatsApp)

              </div>
      </div>
    </div>
  </div>
</template>

<script>

import { getIndexApi, getNameApi } from '@/api/api.js'

export default {
  data() {
    return {
      shows: 0,
      orderNo: '',
      room: '',
      password: '',
      value1: '',
      mingzi: '',
      doorps:'',
    }
  },
  created() {


  },
  methods: {
    orders() {
      this.shows = 0
      console.log(this.shows)
      this.room = ''
      this.password = ''
      this.doorps = ''
    },
    names() {
      this.shows = 1
      console.log(this.shows)
      this.room = ''
      this.password = ''
      this.doorps = ''
    },
    async getList() {
      const res = await getIndexApi({ order_no: this.orderNo })
      if (res.data.code == 0) {
        this.room = res.data.data.room
        this.password = res.data.data.password
        this.doorps = res.data.data.door_pwd
        console.log(this.doorps,'查看大门密码')
      } else {
        alert('没有查询到该订单')
      }
    },
    async getListname() {
      const res = await getNameApi({ name: this.mingzi, date: this.value1 })
      console.log(res, '查看获取到了没')
      this.room = res.data.data.room
      this.password = res.data.data.password
      this.doorps = res.data.data.door_pwd
    }

  }






}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(187, 214, 244);
  /* 浅蓝色背景 */
  color: #004d40;
  /* 深青色文字 */



}
.rooms{
  text-align: center;
}
.header {
  margin: 50px auto;
  width: 90%;
  height: 85%;
  color: #00796b;
  /* 中青色 */
  background-color: rgba(0, 0, 0, 0.1);
  overflow:scroll ;

}

.header::-webkit-scrollbar {
  display: none;
}

.main {
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  background-color: cyan;
}

.footer {
  height: 100px;
  color: #000;
  background-color: #004d40;
  /* 深青色底部 */
}

.top {
  text-align: center;
  padding-top: 10px;
  font-size: 2em;
}

.bottom {
  /* width: 90%; */
  /* height: 85%; */
  /* margin: 0 auto; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
@media (max-width: 768px) {
    .bottom {
        flex-direction: column; /* 变为上下排列 */
        align-items: flex-start; /* 左对齐 */
        
    }

    .bottom_left {
        margin-right: 0; /* 移动端无右边距 */
        margin-bottom: 10px; /* 下边距 */
        width: 100%;
        flex:0;
      }

    .bottom_right {
        width: 100%; /* 确保右侧占满宽度 */
        flex:1;
    }

    .dls{
      font-size: 20px;
    }
    

}



.bottom_left {
  /* width: 50%;
  height: 80%; */
  height: 400px;
  flex: 0;
  background-color: #fff;
  border-radius: 20px;
  /* margin: 100px; */
  margin-right: 20px;
  text-align: center;
  /* line-height: 400px; */
  font-size: 40px;
}

.bottom_left h2 {
  text-align: center;
  margin-top: 20px;
}

.bottom_right {
  /* width: 40%;
  height: 80%; */
  /* height: 500px; */
  background-color: #fff;
  /* margin: 40px; */
  border-radius: 20px;
  flex: 1;
}

.bq {
  width: 240px;
  height: 240px;
}

.imgs {
  width: 100px;
  height: 100px;
}

.zj {
  width: 80%;
  height: 80%;
  margin: 10px auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.zj dl {
  width: 25%;
}

.zj dl dt {
  text-align: center;
}

.zj dl dd {
  text-align: center;
}

.bottom_top {
  /* width: 100%;
  height: 10%; */
  padding: 20px;
  font-size: 12px;
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid #ccc;
  border-radius: 20px;
}

.bottom_top div {
  /* width: 40%; */
  /* height: 80%; */
  background-color: rgb(243, 243, 247);
  text-align: center;
  font-weight: 900;
  font-size: 12px;
}

.bottom_input {

  display: flex;
  align-items: center;
  margin: 5px;
  /* justify-content: space-between; */
}

.order {
  width: 80%;
  height: 20%;
  margin-top: 10px;
  margin-left: 10%;
}

.orderHao {
  width: 50%;
  height: 20px;
  margin-left: 10px;
  border: none;
  background-color: #ccc;
  border-radius: 10px;
  padding: 10px;
  
}



.btn {

  padding: 10px;
  background-color: #004d40;
  color: #fff;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  /* width: 40%;
  margin: 0 auto; */
}

.bottom_bom {
  padding: 20px 10px;
  background-color: rgb(243, 243, 247);
  margin: 10px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

}

dd {
  margin-top: 20px;
}

.zhanshi {
  padding: 10px;
  /* height: 200px; */
  background-color: red;
  background-color: rgb(243, 243, 247);
  margin: 10px 10px;
  border-radius: 10px;
  font-size: 12px;
  /* overflow: scroll; */
}

.zhanshis{
  padding: 10px;
  /* height: 150px; */
  background-color: red;
  background-color: rgb(243, 243, 247);
  margin: 10px 10px;
  border-radius: 10px;
  font-size: 12px;
  /* overflow: scroll; */
}

.room {
  display: flex;
  /* font-size: 20px; */
  margin: 20px auto;
}



.block {
  margin-left: 40px;
}


.nameaaaa {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 12px;
}




/* 媒体查询 */
/* @media (max-width: 1200px) {
  .bottom_left, .bottom_right {
    width: 70%;
    margin: 50px auto;
  }
} */
/* 
@media (max-width: 768px) {
  .bottom_left, .bottom_right {
    width: 90%;
    margin: 20px auto;
  }

  .top {
    font-size: 1.5em;
  }

  .zj dl {
    width: 45%;
  }

  .imgs {
    max-width: 80px; 
  }
}

@media (max-width: 375px) {
  .bottom_left, .bottom_right {
    width: 100%;
    margin: 10px auto;
  }

  .top {
    font-size: 1.2em;
  }

  .zj dl {
    width: 100%;
  }

  .order, .btn {
    width: 90%;
    margin-left: 5%;
  }

  .footer {
    height: 80px;
  }

  .imgs {
    max-width: 60px; 
  }
} */
</style>
