import request from '../utils/request'

//
export function getIndexApi(params) {
    return request({
        method: 'get',
        url: '/api/queryByOrderNo',
        params
    })
}

export function getNameApi(params){
    return request({
        method:'get',
        url:'/api/queryByName',
        params
    })
}





