// request.js
import axios from 'axios'
const instance = axios.create({
    baseURL: 'https://aly053.qdytcy.cn', 
    timeout: 2500
});
console.log(instance.baseURL,'1111')
// 添加请求拦截器 
instance.interceptors.request.use(function (config) {
    // 在发送请求之前做些什么
    // const token = localStorage.getItem('token')
    // config.headers.Authorization = "Bearer " + token;
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    console.log(response.data, 'requset')
    // if (response.data.code === 400) {
    //     // 处理客户端请求错误，可能是请求参数有误等情况
    //     alert(response.data.msg);
    // } else if (response.data.code === 401) {
    //     // 处理未授权错误，重定向到登录页面
    //     window.location.href = '/login';
    // } else if (response.data.code === 500) {
    //     alert(response.data.msg); 关了我嗯呢
    // }
    return response;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export default instance